body {
  background-color: #394dcd;
}
.map {
  width: 100%;
  height: 100vh;
}
.ol-control button {
  background-color: rgba(40, 40, 40, 0.8) !important;
}
.ol-control button:hover {
  background-color: rgba(40, 40, 40, 1) !important;
}

@font-face {
    font-family: LCD14;
    src: url(../fonts/LCD14.otf) format("opentype");
}

@font-face {
    font-family: LCD14Condensed;
    src: url(../fonts/LCD14Condensed.otf) format("opentype");
}

@font-face {
    font-family: LCD14ItalicCondensed;
    src: url(../fonts/LCD14ItalicCondensed.otf) format("opentype");
}

@font-face {
    font-family: LCD14Italic;
    src: url(../fonts/LCD14Italic.otf) format("opentype");
}